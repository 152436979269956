<template>
	<div class="bg-Hight">
		<div class="content">
			<div class="title">
				基本参数
			</div>
			<ul class="list config" element-loading-text="加载中...">
				<li @click="binType(0)">
					<div class="name">安卓安装包</div>
					<div class="input">
						<el-upload accept=".apk" class="fupload" list-type="picture-card"
							:on-success="handleAvatarSuccess" :headers="Headers" :action="ActionUrl">
							<i class="icon iconfont icon-shangchuan"></i>
						</el-upload>
					</div>
				</li>
				<li v-if="form.android_package" class="ftime">
					<div class="name">安装包链接</div>
					<div class="input">
						{{form.android_package}}
					</div>
				</li>
				<li class="ftime">
					<div class="name">安卓下载地址</div>
					<div class="input">
						<el-input clearable type="text" v-model="form.android_down" placeholder="请输入安卓下载地址">
						</el-input>
					</div>
				</li>
				<li class="ftime">
					<div class="name">安卓APP版本</div>
					<div class="input">
						<el-input clearable type="text" v-model="form.android_version" placeholder="请输入版本">
						</el-input>
					</div>
				</li>
				<li class="ftime">
					<div class="name">APP更新内容</div>
					<div class="input">
						<el-input clearable type="textarea" v-model="form.app_update_content" placeholder="请输入更新内容">
						</el-input>
					</div>
				</li>
				<li class="ftime">
					<div class="name">默认密码</div>
					<div class="input">
						<el-input clearable type="text" v-model="form.default_password" placeholder="请输入默认密码">
						</el-input>
					</div>
				</li>
				<li class="ftime">
					<div class="name">苹果下载链接</div>
					<div class="input">
						<el-input clearable type="text" v-model="form.apple_ownload_link" placeholder="请输入苹果下载链接">
						</el-input>
					</div>
				</li>
				<li class="ftime">
					<div class="name">联系电话</div>
					<div class="input">
						<el-input clearable type="text" v-model="form.service_phone" placeholder="请输入联系电话">
						</el-input>
					</div>
				</li>
				<li class="ftime">
					<div class="name">商城自动确认订单天数</div>
					<div class="input">
						<el-input clearable type="number" v-model="form.confirm_orders" placeholder="请输入商城自动确认订单天数">
						</el-input>
					</div>
				</li>
				<li class="ftime">
					<div class="name">商城退款成功后提示消失天数</div>
					<div class="input">
						<el-input clearable type="number" v-model="form.refund" placeholder="请输入商城退款成功后提示消失天数">
						</el-input>
					</div>
				</li>
				<li @click="binType(1)">
					<div class="name">logo</div>
					<div class="input">
						<!-- 预览图 -->
						<div class="imgList" v-if="form.logo">
							<img class="dialog-img" :src="form.logo" />
						</div>
						<el-upload accept=".png,.jpg" class="fupload" list-type="picture-card"
							:on-success="handleAvatarSuccess" :headers="Headers" :action="ActionUrl">
							<i class="icon iconfont icon-shangchuan"></i>
						</el-upload>
					</div>
				</li>
				<li @click="binType(2)">
					<div class="name">产业奖补背景</div>
					<div class="input">
						<!-- 预览图 -->
						<div class="imgList" v-if="form.industry">
							<img class="dialog-img" :src="form.industry" />
						</div>
						<el-upload accept=".png,.jpg" class="fupload" list-type="picture-card"
							:on-success="handleAvatarSuccess" :headers="Headers" :action="ActionUrl">
							<i class="icon iconfont icon-shangchuan"></i>
						</el-upload>
					</div>
				</li>
				<li @click="binType(3)">
					<div class="name">就业奖补背景</div>
					<div class="input">
						<!-- 预览图 -->
						<div class="imgList" v-if="form.employment">
							<img class="dialog-img" :src="form.employment" />
						</div>
						<el-upload accept=".png,.jpg" class="fupload" list-type="picture-card"
							:on-success="handleAvatarSuccess" :headers="Headers" :action="ActionUrl">
							<i class="icon iconfont icon-shangchuan"></i>
						</el-upload>
					</div>
				</li>
				<li @click="binType(4)">
					<div class="name">招商引资背景</div>
					<div class="input">
						<!-- 预览图 -->
						<div class="imgList" v-if="form.investment">
							<img class="dialog-img" :src="form.investment" />
						</div>
						<el-upload accept=".png,.jpg" class="fupload" list-type="picture-card"
							:on-success="handleAvatarSuccess" :headers="Headers" :action="ActionUrl">
							<i class="icon iconfont icon-shangchuan"></i>
						</el-upload>
					</div>
				</li>
				<li @click="binType(5)">
					<div class="name">党建活动背景</div>
					<div class="input">
						<!-- 预览图 -->
						<div class="imgList" v-if="form.party">
							<img class="dialog-img" :src="form.party" />
						</div>
						<el-upload accept=".png,.jpg" class="fupload" list-type="picture-card"
							:on-success="handleAvatarSuccess" :headers="Headers" :action="ActionUrl">
							<i class="icon iconfont icon-shangchuan"></i>
						</el-upload>
					</div>
				</li>
				<li @click="binType(6)">
					<div class="name">环境住房健康背景</div>
					<div class="input">
						<!-- 预览图 -->
						<div class="imgList" v-if="form.environmental">
							<img class="dialog-img" :src="form.environmental" />
						</div>
						<el-upload accept=".png,.jpg" class="fupload" list-type="picture-card"
							:on-success="handleAvatarSuccess" :headers="Headers" :action="ActionUrl">
							<i class="icon iconfont icon-shangchuan"></i>
						</el-upload>
					</div>
				</li>
				<li @click="binType(7)">
					<div class="name">惠民购背景</div>
					<div class="input">
						<!-- 预览图 -->
						<div class="imgList" v-if="form.huimin">
							<img class="dialog-img" :src="form.huimin" />
						</div>
						<el-upload accept=".png,.jpg" class="fupload" list-type="picture-card"
							:on-success="handleAvatarSuccess" :headers="Headers" :action="ActionUrl">
							<i class="icon iconfont icon-shangchuan"></i>
						</el-upload>
					</div>
				</li>
				<li>
					<div class="name">上架审核显示/隐藏</div>
					<div class="input">
						<el-switch :active-value="1" :inactive-value="0" v-model="form.review_hidden"
							active-color="#00ad79" inactive-color="#ff4949" />
					</div>
				</li>
			</ul>
			<div class="title">
				<p @click="selectab(0,form.user_agreement)" :class="[tab==0?'current':'']">用户协议</p>
				<p @click="selectab(1,form.privacy_policy)" :class="[tab==1?'current':'']">隐私政策</p>
				<p @click="selectab(2,form.join_party)" :class="[tab==2?'current':'']">入党流程</p>
				<p @click="selectab(3,form.party_fee)" :class="[tab==3?'current':'']">党费说明</p>
			</div>
			<div v-loading="loading">
				<!-- 图文详情 -->
				<content ref="content" :showname="false" @Submitinput="Submitinput">
				</content>
			</div>
			<ul class="list">
				<li>
					<div class="input">
						<el-button @click="submit" color="#00ad79" class="el-white" style="color: white" size="small">
							保存修改</el-button>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import content from '@/components/content.vue'
	import {
		request,
		api
	} from "@/api/config";
	export default {
		components: {
			content
		},
		data() {
			return {
				loading:false,
				Headers: {
					token: ''
				},
				ActionUrl: '',
				form: {
					android_package: '',
					android_down: '',
					android_version: '',
					app_update_content: '',
					default_password: '',
					apple_ownload_link: '',
					confirm_orders: '',
					refund: '',
					logo: '',
					industry: '',
					employment: '',
					investment: '',
					party: '',
					environmental: '',
					user_agreement: '',
					privacy_policy: '',
					review_hidden: 1,
					party_fee:'',
					huimin:'',
					service_phone:''
				},
				type: 0,
				tab: 0,
			}
		},
		created() {
			// 上转图片url
			this.Headers.token = sessionStorage.getItem('token')
			this.Headers.appid = sessionStorage.getItem('appid')
			this.Headers.nonce = sessionStorage.getItem('nonce')
			this.Headers.timestamp = sessionStorage.getItem('timestamp')
			this.Headers.sign =sessionStorage.getItem('sign')
			
			
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/Upload/FormUpload'
			this.getinfo()
		},
		methods: {
			// 类型
			selectab(e, content) {
				let that = this
				this.loading = true
				this.$refs.content.emptyContent()
				this.tab = e
				// 赋值
				this.$refs.content.setContent(content)
				setTimeout(function() {
					that.loading = false
				}, 500)
			},
			// 提交
			submit() {
				this.$http.post(api.setConfig, this.form)
					.then((res) => {
						ElMessage({
							type: 'success',
							message: '更新成功',
						})
						this.getinfo()
					})
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.getConfig)
					.then((res) => {
						this.form = res.data
						this.form.review_hidden = Number(res.data.review_hidden)
						// 赋值
						this.$refs.content.setContent(res.data.user_agreement)
					})

			},
			// 上传
			handleAvatarSuccess(res, file) {
				if (this.type == 0) {
					this.form.android_package = res.data.url
				}
				if (this.type == 1) {
					this.form.logo = res.data.url
				}
				if (this.type == 2) {
					this.form.industry = res.data.url
				}
				if (this.type == 3) {
					this.form.employment = res.data.url
				}
				if (this.type == 4) {
					this.form.investment = res.data.url
				}
				if (this.type == 5) {
					this.form.party = res.data.url
				}
				if (this.type == 6) {
					this.form.environmental = res.data.url
				}
				if (this.type == 7) {
					this.form.huimin = res.data.url
				}
				
			},
			// 类型
			binType(e) {
				this.type = e
			},
			// 图文详情
			Submitinput(e) {
				if (this.tab == 0) {
					this.form.user_agreement = e
				} else if (this.tab == 1) {
					this.form.privacy_policy = e
				} else if (this.tab == 2) {
					this.form.join_party = e
				}
				else if (this.tab == 3) {
					this.form.party_fee = e
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.list {
		padding-left: 60px;

		.appurl {
			padding-left: 150px;
			color: #666;
		}

		li {
			width: 100%;
			font-size: 14px;
			display: flex;
			margin-bottom: 18px;

			.name {
				padding: 0 12px 0 0;
				line-height: 32px;
				width: 150px;
			}

			.input {
				display: flex;
				align-items: center;

				i {
					font-size: 45px;
				}

				.fupload {
					display: inline-block;
				}
			}
		}
	}

	.content {
		padding: 20px;

		.title {
			color: #000;
			margin: 30px 0;
			font-size: 15px;
			width: 100%;
			display: flex;
			border-bottom: 1px solid #f2f2f2;
			align-items: center;

			.current {
				color: #00ad79;
				border-bottom: 2px solid #00ad79;
			}

			p {
				height: 40px;
				line-height: 40px;
				margin-right: 30px;
				text-align: center;
				cursor: pointer;
			}
		}
	}
</style>