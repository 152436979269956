import request from './request'

const api = {
	// 获取系统配置
	getConfig: '/system/getConfig',
	// 提交系统配置
	setConfig: '/system/setConfig',
}

export {
	request,
	api
}
